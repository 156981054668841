import { createMuiTheme } from '@material-ui/core/styles'

const palette = {
  gray: {
    dark: '#687782',
    med: '#f0f0f0',
    light: '#f8f8f8',
    text: '#213340',
    reqFieldsText: '#425563'
  },
  white: '#fff',
  black: '#000',
  primary: {
    main: '#0057B8'
  },
  secondary: {
    main: '#AA0061'
  },
  danger: '#F44336',
  roles: {
    FORMULATOR: '#e08046',
    RESEARCH_DEV_MANAGER: '#e08046',
    SALES_MANAGER: '#8dd1ed',
    SALES_DIR_VP: '#4f90ab',
    SALES_REP: '#93b16e',
    PM: '#e1bb33',
    PM_MANAGER: '#e1bb33',
    PROD_MARKETING: '#93b16e',
    RESEARCH_DEV: '#e08046',
    FIELD_SERVICE: '#ce93d8'
  }
}

const theme = createMuiTheme({
  components: {
    Card: {
      header: {
        backgroundColor: palette.gray.med,
        color: palette.gray.text
      }
    },
    ModalBase: {
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      modalWrapper: {
        width: '670px',
        borderRadius: '4px',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: palette.gray.light,
        outline: 'none'
      },
      header: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        padding: '23px 30px 23px 50px',
        color: '#000',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '30px',
        fontWeight: '300',
        backgroundColor: palette.white,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
      },
      modalContent: {
        height: '750px',

        '@media (max-height: 850px)': {
          height: '650px'
        },

        '@media (max-height: 750px)': {
          height: '550px'
        }
      }
    }
  },
  productData: {
    colors: {
      icon: '#d5d9dd'
    },
    components: {
      EditableList: {
        header: {
          root: {
            backgroundColor: palette.gray.med,
            boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.2)'
          },
          cell: { padding: '14px 50px', color: palette.gray.text }
        },
        body: {
          root: {
            backgroundColor: palette.white
          },
          row: {},
          cell: {
            border: 0,
            padding: '8px 16px'
          }
        }
      }
    }
  },
  palette,
  typography: {
    weight: {
      bold: 500,
      normal: 300,
      h2: 400
    },
    h1: {
      fontSize: '42px',
      color: '#213340',
      fontWeight: 300
    },
    usm: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    ulg: '20px'
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiRadio: {
      disableRipple: true
    }
  },
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        '@media (min-width:1280px)': {
          maxWidth: '1204px'
        }
      }
    },
    MuiAppBar: {
      colorPrimary: {
        color: '#213340',
        backgroundColor: '#ffffff'
      }
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 0px 8px 0px rgba(196, 196, 196, 1)'
      }
    },
    MuiDialog: {
      paper: {
        overflow: 'visible'
      }
    },
    MuiTable: {
      root: {
        borderCollapse: 'inherit'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '16px'
      },
      body: {
        color: '#213340'
      },
      head: {
        fontSize: '16px',
        color: '#425563'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: 'rgba(0, 0, 0, 0.2)'
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: palette.primary.main
        }
      }
    },
    MuiInputBase: {
      multiline: {
        overflow: 'hidden'
      },
      inputMultiline: {
        left: 0
      }
    },
    MuiChip: {
      root: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#213340'
      }
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#213340',

        '&$disabled': {
          color: '#98A4AE'
        }
      }
    },
    MuiRadio: {
      root: {
        color: '#CBD1D6'
      },
      colorPrimary: {
        '&$disabled': {
          color: '#CBD1D6',
          opacity: 0.5
        },
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&.Mui-checked:hover': {
          backgroundColor: 'transparent !important'
        }
      }
    },
    Link: {
      color: '#0057B8'
    },
    MuiButton: {
      colorInherit: {
        borderColor: '#98A4AE',
        color: '#425563'
      }
    }
  }
})

export default theme
