import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingView = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  ${({ styles }) => styles}
`

export default function Loading({
  styles = '',
  size = 40,
  className,
  color = 'primary'
}) {
  return (
    <LoadingView styles={styles} className={className}>
      <CircularProgress size={size} color={color} />
    </LoadingView>
  )
}

Loading.propTypes = {
  styles: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string
}
