import React from 'react'
import PropTypes from 'prop-types'
import Client from 'apollo-client'
import { withAuth } from '@okta/okta-react'
import { RestLink } from 'apollo-link-rest'
import { onError } from 'apollo-link-error'
import { ApolloProvider } from 'react-apollo'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import get from 'lodash/get'
import { ApolloLink } from 'apollo-link'

const ClientProvider = ({ children, auth }) => {
  const client = React.useMemo(
    () =>
      new Client({
        cache: new InMemoryCache(),
        link: ApolloLink.from([
          onError(({ networkError }) => {
            if (networkError) {
              networkError.message = get(
                networkError,
                'result.error.message',
                'There was an issue with your network. Please try again later.'
              )
            }
          }),
          setContext(async () => {
            const token = await auth.getAccessToken()
            return { headers: { authorization: `Bearer ${token}` } }
          }),
          new RestLink({
            uri: process.env.REACT_APP_API_URI,
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              'X-Ext-Auth-Scheme': 'new'
            }
          })
        ])
      }),
    [auth]
  )

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
    </ApolloProvider>
  )
}

ClientProvider.propTypes = {
  children: PropTypes.node,
  auth: PropTypes.shape({ getAccessToken: PropTypes.func })
}

export default withAuth(ClientProvider)
